<template>
    <div>
        <header-portrate />
        <exports-print ref="exportbar" />
        <tabsComp :tabs="tabs" />
        <v-row class="mainrow m-2" :style="`direction:`+lang.dir+`;margin-top:-35px !important;`">
            <v-row style="margin-top:45px;">

            </v-row>
            <v-row>
                <v-col cols="12" class="md-3" :style="`direction:`+lang.dir+`;`">
                <v-row>
                    <v-col cols="2" class="md-3" :style="`direction:${lang.dir};`">
                        <label>{{ lang.report_day }}</label>
                        <b-form-input class="inborder" v-model="chose_day" type="date" />
                    </v-col>
                    <v-col cols="1" class="md-3" :style="`direction:${lang.dir};`">
                        <label>{{ lang.start_time }}</label>
                        <b-form-input class="inborder" v-model="start_time" type="time" />
                    </v-col>
                    <v-col cols="1" class="md-3" :style="`direction:${lang.dir};`">
                        <label>{{ lang.exit_time }}</label>
                        <b-form-input class="inborder" v-model="exit_time" type="time" />
                    </v-col>
                    <v-col cols="2" class="md-3" :style="`direction:${lang.dir};`">
                        <label>{{ lang.chose_user }}</label>
                        <b-form-select class="selborder" v-model="userid" :options="usersList" />
                    </v-col>
                    <v-col cols="2" class="md-3" :style="`direction:${lang.dir};`">
                        <label>{{ lang.report_type }}</label>
                        <b-form-select class="selborder" v-model="report_type" :options="reportTypes" />
                    </v-col>
                    <v-col cols="2" class="md-3" :style="`direction:${lang.dir};`">
                        <label>{{ lang.printer_type }}</label>
                        <b-form-select class="selborder" v-model="printer_type" :options="printerType" />
                    </v-col>
                    <v-col cols="2" class="md-3" :style="`direction:${lang.dir};`">
                        <div style="color:#000;"><label>.</label></div>
                        <b-button type="button" @click="getReport()" id="addBTN" style="font-size:.8rem;" variant="success">استعراض التقرير</b-button>
                    </v-col>
                </v-row>
                </v-col>
            </v-row>
      </v-row>

      
    </div>
</template>

<script>
import HeaderPortrate from '@/components/Header-Portrate.vue'
import ExportsPrint from '@/components/exports-print.vue'
import TabsComp from '@/components/tabsComp.vue'


import axios from 'axios'
export default {
    components: {
        HeaderPortrate,ExportsPrint,TabsComp
    },
    data() {
        return {
            chose_day: '',
            start_time: '00:00:00',
            exit_time: '02:00:00',
            userid: 0,
            report_type: 1,
            printer_type: 1,

            search: '',
            mobile: '',
            checkup_number: '',
            tablerows: [],
            pl: {
                c1: '',
                c2: '',
                c3: '',
                text: ''
            },
            sd: {
                day: 1,
                month: (new Date()).getMonth() + 1,
                year: (new Date()).getFullYear() - 1,
            },
            ed: {
                day: (new Date()).getDate(),
                month: (new Date()).getMonth() + 1,
                year: (new Date()).getFullYear(),
            },
            users: [],

        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                    return this.$store.state.lang.en;
                }else{
                    return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        usersList: function()
        {
            let t = [];
            for(let i=0; i<this.users.length;i++){
                t.push({text: this.users[i].full_name, value: this.users[i].id});
            }
            return t;
        },
        reportTypes: function()
        {
            return [
                {text: 'تقرير احصائي', value: 1 },
                {text: 'تقرير قائمة الفواتير ', value: 2 },
            ]
        },
        printerType: function()
        {
            return [
                {text: 'طابعة A4', value: 1 },
                {text: 'طابعة ايصالات', value: 2 },
            ]
        },
        tabs: function() 
        {
          const menu = this.$store.getters['state'].menu;
            const license = this.$store.getters['state'].licenseType.activemenu;
            const alicense = this.$store.getters['state'].licenseType
            let currentMenu = menu[license];
            if(!license){
              return false;
            }
            let cMenu = {main: {},sub:[]};
            Object.values(currentMenu).forEach(value =>{
            const cM = localStorage.getItem('currentMenu');
            if(value.main.index == cM){
                cMenu = value.sub;
            }else if(cM == null && value.main.index == 1){
                cMenu = value.sub;
            }
            });
        
            let t = []
            Object.values(cMenu).forEach(value =>{
            
                if(this.$route.path == value.href){
                    value.class = "mytab";
                }else{
                    value.class = "mytab4";
                }
                if(this.lang.langname == 'ar'){
                    value.name = value.arname
                }else{
                    value.name = value.enname
                }
                for(let i =0; i< value.groupid.length;i++){
                    if(value.groupid[i] == this.$store.getters['state'].group_id){
                    if(alicense[value.perms[0]] || value.perms[0] == 'any')
                        t.push(value)
                    }
                }
            
            })
        
            return t;
        }
    },
    methods: {
      getReport(){
        window.open('/api/sales-report.php?userid='+this.userid+'&day='+this.chose_day+'&star_time='+this.start_time+'&exit_time='+this.exit_time+'&report_type='+this.report_type+'&print_type='+this.printer_type, '_blank')
      },
      getUsers(){
        const post = new FormData();
        post.append('defBranch',localStorage.getItem('defBranch'));
        post.append('financeYear',localStorage.getItem('financeYear'));
        post.append('type','getInvSettings');
        post.append('auth',this.$cookies.get(this.$COOKIEPhase));
        post.append('data[all]','all');
        axios.post(
            this.$SAMCOTEC.r_path, post
        ).then(
            (response) => {
                const res = response.data;
                if(res != '' && res.error.number == 200){
                    this.users = res.results.data.users;
                }
            }
        )
      }
    },
    created() {
      this.getUsers();
    }
}
</script>
